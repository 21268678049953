const DE = {
  translation: {
    pageTitle: {
      '400': 'Fehlerhafte Anfrage – 400',
      '401': 'Nicht authorisiert – 401',
      '403': 'Kein Zugriff – 403',
      '404': 'Nicht gefunden – 404',
      '500': 'Serverfehler – 500',
      account: 'Dein Account',
      login: 'Anmelden',
      dashboard: 'Dashboard',
      start: 'Start',
      courses: 'Kurse',
      coursesCreate: 'Kurs erstellen',
      exercises: 'Übungen',
      exercisesCreate: 'Übung erstellen',
      questions: 'Fragen',
      questionsCreate: 'Frage erstellen',
      units: 'Lektionen',
      unitsCreate: 'Lektion erstellen',
      users: 'Nutzer:innen',
      usersCreate: 'Nutzer:in erstellen',
    },
    alerts: {
      loginToAccess: 'Bitte melde Dich an, um auf den Inhalt zugreifen zu können',
    },
    account: {
      headline: 'Dein Konto',
      welcome: 'Willkommen zurück, {{firstName}}!',
      notFound: 'Account nicht gefunden',
      security: {
        passwordInfo: 'Falls Du Dein Passwort ändern möchtest, gib hier ein neues Passwort ein und bestätige es.',
      },
    },
    auth: {
      invalidCredentials: 'Anmeldedaten sind nicht korrekt',
    },
    courses: {
      headline: 'Kurse',
      curriculum: {
        alert: 'Achtung: Jede Änderung ist DIREKT live!',
        empty: 'Keine Einträge vorhanden. Suche Kapitel und klicke sie an, um sie hinzuzufügen...',
      },
      testStatisticsSections: 'Test Statistik Sektionen',
      wpProductId: 'Woocommerce Product ID',
      create: {
        headline: 'Kurs erstellen',
      },
    },
    exercises: {
      headline: 'Übungen',
      create: {
        headline: 'Übung erstellen',
        welcome: 'Erstelle einen neue Übung',
        createdSuccessfully: 'Übung erfolgreich erstellt',
      },
    },
    questions: {
      headline: 'Fragen',
      create: {
        headline: 'Frage erstellen',
        welcome: 'Erstelle einen neue Frage',
        createdSuccessfully: 'Frage erfolgreich erstellt',
      },
    },
    units: {
      headline: 'Lektionen',
      create: {
        headline: 'Lektion erstellen',
        welcome: 'Erstelle einen neue Lektion',
        createdSuccessfully: 'Lektion erfolgreich erstellt',
      },
      audioUrlHelper: 'Link file: https://domain.url/file.mp3',
      videoUrlHelper: 'Example: https://www.youtube.com/watch?v=ArcI4A5nvBo',
    },
    users: {
      headline: 'Nutzer:innen',
      create: {
        headline: 'Nutzer:in erstellen',
        welcome: 'Erstelle einen neuen Account mit Kunden-Rolle (Bitte korrekte WP User ID setzen!)',
        createdSuccessfully: 'Nutzer:in erfolgreich erstellt',
      },
    },
    form: {
      helper: {
        explanations: {
          general: 'Jeder Index einer Erklärung bezieht sich auf den Index einer Antwort (0 zu 0, etc.)',
        },
        questionTags: {
          quantity: "Max. Anzahl für diesen Tag. '-1' für so viele wie vorhanden",
          tag: 'Aus Fragen mit diesem Tag auswählen',
          testRun: "Nur angeben, wenn pro Testlauf andere Tags ausgewählt werden sollen. Beginnt mit '0'",
        },
        testRunSequences: 'Benötigt, wenn per Testdurchlauf wiederholbar',
        processingTime: 'Dauer in Sekunden',
      },
    },
    generic: {
      audioUrl: 'Audio URL',
      abbreviation: 'Kürzel',
      access: 'Zugang',
      account: 'Konto',
      actions: 'Aktionen',
      add: 'Hinzufügen',
      addressAddition: 'Adresszusatz',
      apply: 'Anwenden',
      answer: 'Antwort',
      answers: 'Antworten',
      articles: 'Artikel',
      authors: 'Autoren',
      back: 'Zurück',
      cancel: 'Abbrechen',
      change: 'Ändern',
      chapters: 'Kapitel',
      changed: 'Geändert',
      city: 'Stadt',
      content: 'Inhalte',
      contentType: 'Inhaltstyp',
      country: 'Land',
      countryPlaceholder: 'Deutschland',
      course: 'Kurs',
      courses: 'Kurse',
      create: 'Erstellen',
      created: 'Erstellt',
      createdAt: 'Erstellt',
      curriculum: 'Curriculum',
      dashboard: 'Dashboard',
      delete: 'Löschen',
      description: 'Beschreibung',
      duration: 'Dauer',
      email: 'E-Mail-Adresse',
      end: 'Ende',
      exercise: 'Übung',
      exercises: 'Übungen',
      explanation: 'Erklärung',
      explanationBlocks: 'Erklärungsblock',
      explanations: 'Erklärungen',
      filter: 'Filtern',
      firstName: 'Vorname',
      general: 'Allgemein',
      hint: 'Hinweis',
      id: 'ID',
      icon: 'Icon',
      image: 'Bild',
      images: 'Bilder',
      index: 'Index',
      label: 'Label',
      language: 'Sprache',
      lastLoginAt: 'Zuletzt eingeloggt am',
      lastName: 'Nachname',
      list: 'Liste',
      loading: 'Lade...',
      login: 'Anmelden',
      logout: 'Abmelden',
      logs: 'Logs',
      memberships: 'Mitgliedschaften',
      menu: 'Menü',
      name: 'Name',
      new: 'Neues',
      noEntries: 'Keine Einträge vorhanden',
      noEntriesForSearchTerm: 'Keine Einträge für den Suchbegriff gefunden',
      pages: 'Seiten',
      paragraphs: 'Paragraphen',
      password: 'Passwort',
      passwordRepetition: 'Passwort-Wiederholung',
      persons: 'Personen',
      phoneNumber: 'Telefonnummer',
      pleaseBePatient: 'Bitte habe einen Moment Geduld.',
      pleaseSelect: 'Bitte auswählen...',
      prefix: 'Prefix',
      price: 'Preis',
      private: 'Privat',
      profile: 'Profil',
      processingTime: 'Bearbeitungszeit',
      references: 'Referenzen',
      request: 'Anfragen',
      reset: 'Zurücksetzen',
      role: 'Rolle',
      save: 'Speichern',
      saving: 'Speichern...',
      search: 'Suchen',
      security: 'Sicherheit',
      sessions: 'Sessions',
      slug: 'Slug',
      sourceId: 'Quell-ID',
      summary: 'Zusammenfassung',
      start: 'Start',
      status: 'Status',
      street: 'Straße & Hausnummer',
      title: 'Titel',
      unit: 'Lektion',
      units: 'Lektionen',
      unspecified: 'beliebig',
      updated: 'Geändert',
      updatedAt: 'Geändert',
      updateSearch: 'Suche anpassen',
      user: 'Nutzer:in',
      users: 'Nutzer:innen',
      repeatable: 'Wiederholbar',
      testRunSequences: 'Testdurchlauf Sequenzen',
      task: 'Aufgabe',
      tag: 'Tag',
      quantity: 'Anzahl',
      question: 'Frage',
      theme: 'Theme',
      testRun: 'Testdurchlauf',
      questionTags: 'Fragen Tags',
      questions: 'Fragen',
      questionEffect: 'Frageneffekt',
      shuffleQuestions: 'Fragen mixen',
      solution: 'Lösung',
      videoUrl: 'Video URL',
      website: 'Webseite',
      showResult: 'Ergebnis anzeigen',
      selectQuestionsBy: 'Fragen auswählen nach',
      tags: 'Tags',
      text: 'Text',
      type: 'Typ',
      zipCode: 'Postleitzahl',
      wpUserId: 'WP User ID',
    },
    table: {
      item: 'Eintrag',
      items: 'Einträge',
      ofPages: 'von',
      orderBy: 'Sortieren nach',
      rowsPerPage: 'Zeilen pro Seite',
    },
    dashboard: {
      headline: 'Dashboard',
      welcome: 'Inhalte anlegen und verwalten',
    },
    errors: {
      changesFailed: 'Änderungen speichern ist fehlgeschlagen',
      changesLost: 'Änderungen verworfen',
      default: 'Etwas ist schief gelaufen',
      failedToDelete: 'Löschen fehlgeschlagen',
      failedToLoad: 'Laden fehlgeschlagen',
      failedToSave: 'Speichern fehlgeschlagen',
      failedToSendVerifyEmail: 'Bestätigungs-E-Mail konnte nicht an {{email}} versandt werden.',
      unknown: 'Ein unbekannter Fehler ist aufgetreten',
    },
    order: {
      byCreatedAtDesc: 'Erstellt (Neuste)',
      byCreatedAtAsc: 'Erstellt (Älteste)',
      byTitleAsc: 'Titel (A->Z)',
      byTitleDesc: 'Titel (Z->A)',
      byUpdatedAtDesc: 'Bearbeitet (Neuste)',
      byUpdatedAtAsc: 'Bearbeitet (Älteste)',
      byStatusAsc: 'Status (A->Z)',
      byStatusDesc: 'Status (Z->A)',
    },
    success: {
      created: 'Successfully created',
      updated: 'Successfully updated',
      deleted: 'Successfully deleted',
      changesSaved: 'Änderungen wurden gespeichert',
    },
    login: {
      forgotPassword: 'Passwort vergessen?',
      formHeadline: 'Anmelden',
      headline: 'Melde dich an',
      loggedIn: 'Erfolgreich angemeldet',
      loginBtn: 'Anmelden',
    },
    nav: {
      account: 'Konto',
      content: 'Inhalte',
      create: 'Erstellen',
      dashboard: 'Dashboard',
      list: 'Liste',
      login: 'Anmelden',
      register: 'Registrieren',
    },
    errorPage: {
      btnLabel: 'Zur Startseite',
      '400': {
        headline: 'Ein Fehler ist aufgetreten – 400',
        message: 'Etwas mit der Anfrage ist schief gelaufen.',
      },
      '401': {
        headline: 'Nicht authorisiert – 401',
        message: 'Melde dich an, um auf den Inhalt zuzugreifen.',
      },
      '403': {
        headline: 'Keine Zugriffsrechte – 403',
        message: 'Du hast nicht die nötigen Zugriffsrechte für diesen Inhalt.',
      },
      '404': {
        headline: 'Inhalt nicht gefunden – 404',
        message: 'Der gesuchte Inhalt konnte nicht gefunden werden.',
      },
      '500': {
        headline: 'Ein Fehler ist aufgetreten – 500',
        message: 'Das System kann die Anfrage nicht verarbeiten.',
      },
    },
    validation: {
      addAtLeastXChars: 'Bitte gib mind. {{length}} Zeichen an',
      billingAddressTypeMustBeValid: 'Gültige Zahlungsart angeben',
      cityRequired: 'Stadt wird benötigt',
      companyNameRequired: 'Firmenname wird benötigt',
      countryRequired: 'Land wird benötigt',
      creditCardMustBeValid: 'Gültige Kreditkartennummer wird benötigt',
      creditCardNameRequired: 'Name auf der Kreditkarte wird benötigt',
      descriptionRequired: 'Beschreibung wird benötigt',
      emailMustBeValid: 'Gültige E-Mail-Adresse eingeben',
      emailRequired: 'E-Mail-Adresse wird benötigt',
      firstNameRequired: 'Vorname wird benötigt',
      ibanMustBeValid: 'Gültige IBAN wird benötigt',
      lastNameRequired: 'Nachname wird benötigt',
      onlyLettersSpaceAndMinus: 'Bitte nur Buchstaben, Leerzeichen oder Bindestrich eingeben',
      passwordRepetitionRequired: 'Passwort-Wiederholung wird benötigt',
      passwordRequired: 'Passwort wird benötigt',
      passwordsDoNotMatch: 'Die Passwörter müssen übereinstimmen',
      passwordTooShort: 'Passwort benötigt mind. {{passwordMinLength}} Zeichen',
      paymentMethodIdRequired: 'Gültige Zahlungsart angeben',
      phoneNumberMustBeValid: 'Telefonnummer muss aus Zahlen bestehen',
      pleaseFillInAllData: 'Bitte alle nötigen Daten angeben',
      pleaseFillInRequiredFields: 'Bitte alle Pflichtfelder (*) ausfüllen',
      sepaEmailRequired: 'E-Mail-Adresse für das SEPA Mandat wird benötigt',
      sepaNameRequired: 'Name für das SEPA Mandat wird benötigt',
      street: 'Bitte nur Buchstaben, Zahlen, Leerzeichen, Binde- oder Schrägstrich eingeben',
      streetRequired: 'Straße & Hausnummer wird benötigt',
      titleRequired: 'Titel wird benötigt',
      urlMustBeValid: 'Korrekte Webseite eingeben',
      zipCodeMustBeValid: 'Postleitzahl muss aus 5 Zahlen bestehen',
      zipCodeRequired: 'Postleitzahl wird benötigt',
    },
  },
};

export default DE;
