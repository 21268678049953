import { OptionsObject, useSnackbar, WithSnackbarProps } from 'notistack';

let snackbarRef: WithSnackbarProps;
export function Snackbar() {
  snackbarRef = useSnackbar();
  return null;
}

export default class NotificationService {
  public static toast(message: string, options: OptionsObject): void {
    snackbarRef.enqueueSnackbar(message, options);
  }

  public static info(message: string): void {
    this.toast(message, { variant: 'info' });
  }

  public static success(message: string): void {
    this.toast(message, { variant: 'success' });
  }

  public static warning(message: string): void {
    this.toast(message, { variant: 'warning' });
  }

  public static error(message: string): void {
    this.toast(message, { variant: 'error' });
  }
}
