import { createTheme, responsiveFontSizes } from '@mui/material';
import { CSSProperties } from 'react';
import '@mui/lab/themeAugmentation';

import MuiCssBaseline from './overrides/MuiCssBaseline';

/**
 * Add Typography variants
 * https://next.material-ui.com/customization/typography/#adding-amp-disabling-variants
 */

declare module '@mui/material/styles' {
  interface TypographyVariants {
    h0: CSSProperties;
    h7: CSSProperties;
    subtitle: CSSProperties;
    large: CSSProperties;
    body: CSSProperties;
    small: CSSProperties;
  }

  interface TypographyVariantsOptions {
    h0?: CSSProperties;
    h7?: CSSProperties;
    subtitle?: CSSProperties;
    large?: CSSProperties;
    body?: CSSProperties;
    small?: CSSProperties;
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    h0: true;
    h7: true;
    subtitle: true;
    large: true;
    body: true;
    small: true;
    // Disable unused variants
    subtitle1: false;
    subtitle2: false;
    body1: false;
    body2: false;
    caption: false;
    button: false;
    overline: false;
  }
}

/**
 * Add color palettes
 * https://next.material-ui.com/customization/palette/#adding-new-colors
 */

declare module '@mui/material/styles' {
  interface Palette {
    border: Palette['primary'];
    grey1: Palette['primary'];
  }

  interface PaletteOptions {
    border: PaletteOptions['primary'];
    grey1: PaletteOptions['primary'];
  }
}

/**
 * Add zIndex items
 */

declare module '@mui/material/styles/zIndex' {
  interface ZIndex {
    resourceDetailOrderBar: number;
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    outlinedWhite: true;
  }
}

const fallbackFonts = [
  '-apple-system',
  'BlinkMacSystemFont',
  '"Suisse Int\'l"',
  '"Segoe UI"',
  'Roboto',
  '"Helvetica Neue"',
  'Arial',
  'sans-serif',
  '"Apple Color Emoji"',
  '"Segoe UI Emoji"',
  '"Segoe UI Symbol"',
];
const textFontFamily = [...fallbackFonts].join(',');
const headlineFontFamily = [...fallbackFonts].join(',');

const defaultTheme = createTheme();

const theme = createTheme({
  typography: {
    fontFamily: textFontFamily,
    h0: {
      fontFamily: headlineFontFamily,
      fontWeight: 700,
      lineHeight: 1.12,
      letterSpacing: -1.5,
      fontSize: 40,
      [defaultTheme.breakpoints.up('sm')]: {
        fontSize: 50,
      },
      [defaultTheme.breakpoints.up('md')]: {
        fontSize: 64,
      },
      [defaultTheme.breakpoints.up('lg')]: {
        fontSize: 68,
      },
    },
    h1: {
      fontFamily: headlineFontFamily,
      fontSize: 40,
      fontWeight: 700,
      lineHeight: 1.17,
    },
    h2: {
      fontFamily: headlineFontFamily,
      fontSize: 35,
      fontWeight: 700,
      lineHeight: 1.23,
      letterSpacing: 0.25,
    },
    h3: {
      fontFamily: headlineFontFamily,
      fontSize: 28,
      fontWeight: 700,
      lineHeight: 1.23,
      letterSpacing: 0.25,
    },
    h4: {
      fontFamily: headlineFontFamily,
      fontSize: 23,
      fontWeight: 700,
      lineHeight: 1.23,
      letterSpacing: 0.25,
    },
    h5: {
      fontFamily: headlineFontFamily,
      fontSize: 20,
      fontWeight: 700,
      lineHeight: 1.4,
      letterSpacing: 0.15,
    },
    h6: {
      fontFamily: headlineFontFamily,
      fontSize: 16,
      fontWeight: 700,
      lineHeight: 1.4,
      letterSpacing: 0.15,
    },
    h7: {
      fontFamily: headlineFontFamily,
      fontSize: 12,
      fontWeight: 700,
      lineHeight: 1.4,
      letterSpacing: 0.5,
    },
    subtitle: {
      fontFamily: headlineFontFamily,
      fontSize: 22,
      fontWeight: 400,
      lineHeight: 1.57,
      letterSpacing: 0.1,
    },
    large: {
      fontFamily: headlineFontFamily,
      lineHeight: 1.45,
      letterSpacing: 0.15,
      fontSize: 20,
      [defaultTheme.breakpoints.up('sm')]: {
        fontSize: 22,
      },
      [defaultTheme.breakpoints.up('md')]: {
        fontSize: 24,
      },
      [defaultTheme.breakpoints.up('lg')]: {
        fontSize: 26,
      },
    },
    body: {
      fontFamily: textFontFamily,
      fontSize: 15,
      lineHeight: 1.43,
      letterSpacing: 0.1,
    },
    small: {
      fontSize: 12,
      lineHeight: 1.4,
      letterSpacing: 0.4,
    },
  },
  palette: {
    ...defaultTheme.palette,
    primary: {
      main: '#00DFA3',
      contrastText: '#FBFAFF',
    },
    secondary: {
      main: '#000000',
      contrastText: '#FBFAFF',
    },
    border: {
      main: '#E2E1E6',
    },
    grey1: {
      light: '#F4F5F7',
      main: '#E9E9E9',
      dark: '#E0E0E0',
    },
    text: {
      primary: '#000000',
      secondary: '#0B1236',
      disabled: '#E2E1E6',
    },
    background: {
      default: '#F6F6F6',
      paper: '#ffffff',
    },
  },
  components: {
    MuiCssBaseline,
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          h0: 'h1',
          h1: 'h1',
          h2: 'h2',
          h3: 'h3',
          h4: 'h4',
          h5: 'h5',
          h6: 'h6',
          h7: 'h6',
          subtitle: 'span',
          large: 'span',
          body: 'span',
          small: 'span',
        },
      },
    },
  },
});

const responsiveTheme = responsiveFontSizes(theme);

export default responsiveTheme;
