import { getSnapshot, model, Model, modelAction, prop } from 'mobx-keystone';
import i18n from 'i18next';
import { isEqual, omit } from 'lodash';
import { reaction } from 'mobx';

import { defaultSearch, searchMinLength } from '@/constants';
import { Unit, UnitResponse, UnitsResponse } from '@/types';
import { SearchModel, StoreStatusModel } from '@/models';
import { ErrorService, NotificationService, UtilitiesService } from '@/services';

import { HttpService } from '../services';

const search = { ...defaultSearch, order: '-createdAt' };

@model('RootStore/UnitStore')
export default class UserStore extends Model({
  units: prop<Unit[]>(() => []),
  unitsSearch: prop<SearchModel>(() => new SearchModel(search)),
  unitSelected: prop<Unit | undefined>(() => undefined).withSetter(),
  storeStatus: prop<StoreStatusModel>(() => new StoreStatusModel({})),
}) {
  @modelAction
  addUnits = (units: Unit[], total?: number) => {
    this.units = units;
    if (typeof total !== 'undefined') {
      this.unitsSearch.total = total;
      // If less items than current page index, reset current page
      if (this.unitsSearch.total < this.unitsSearch.page * this.unitsSearch.limit) {
        this.unitsSearch.page = 0;
      }
    }
  };

  async getUnits() {
    this.storeStatus.setLoading('getUnits');
    try {
      const response = await HttpService.get<UnitsResponse>(
        '/manage/units',
        UtilitiesService.getSearchQueryString(this.unitsSearch),
      );
      const { data, total, success } = response.data;
      if (data && success) {
        this.addUnits(data, total);
        this.storeStatus.setLoaded('getUnits');
        return;
      }
    } catch (e) {
      ErrorService.handleError(e);
    }
    NotificationService.error(i18n.t('errors.failedToLoad'));
    this.storeStatus.setLoaded('getUnits');
  }

  async retrieveAndSelectUnit(id: string): Promise<boolean> {
    this.storeStatus.setLoading('retrieveAndSelectUnit');
    this.setUnitSelected(undefined);
    try {
      const response = await HttpService.get<UnitResponse>(`/manage/units/${id}`);
      const { data, success } = response.data;
      if (data && success) {
        this.setUnitSelected(data);
        this.storeStatus.setLoaded('retrieveAndSelectUnit');
        return true;
      }
    } catch (e) {
      ErrorService.handleError(e);
    }
    NotificationService.error(i18n.t('errors.failedToLoad'));
    this.storeStatus.setLoaded('retrieveAndSelectUnit');
    return false;
  }

  async createUnit(item: Partial<Unit>) {
    this.storeStatus.setLoading('createUnit');
    try {
      const response = await HttpService.post<UnitResponse>('/manage/units', item);
      const { data, success } = response.data;
      if (data && success) {
        NotificationService.success(i18n.t('success.created'));
        this.storeStatus.setLoaded('createUnit');
        return;
      }
    } catch (e) {
      ErrorService.handleError(e);
    }
    NotificationService.error(i18n.t('errors.failedToSave'));
    this.storeStatus.setLoaded('createUnit');
  }

  async updateSelectedUnit(changes: Partial<Unit>) {
    this.storeStatus.setLoading('updateSelectedUnit');
    if (!this.unitSelected) throw Error('No unit selected');
    try {
      const { id } = this.unitSelected;
      const response = await HttpService.patch<UnitResponse>(`/manage/units/${id}`, changes);
      const { data, success } = response.data;
      if (data && success) {
        this.setUnitSelected(data);
        NotificationService.success(i18n.t('success.updated'));
        this.storeStatus.setLoaded('updateSelectedUnit');
        return;
      }
    } catch (e) {
      ErrorService.handleError(e);
    }
    NotificationService.error(i18n.t('errors.failedToSave'));
    this.storeStatus.setLoaded('updateSelectedUnit');
  }

  onAttachedToRootStore(): () => void {
    const unitSearchReaction = reaction(
      () => getSnapshot(this.unitsSearch),
      () => {
        if (
          !this.unitsSearch.term ||
          this.unitsSearch.term.length === 0 ||
          this.unitsSearch.term.length >= searchMinLength
        ) {
          this.getUnits().catch(console.error);
        }
      },
      {
        // Only return unequal if anything but total changed on searchModel
        equals: (a, b) => isEqual(omit(a, 'total'), omit(b, 'total')),
      },
    );

    return () => {
      unitSearchReaction();
    };
  }
}
