// Colors from material ui Alert component
export enum NotificationTypes {
  default = 'info',
  info = 'info',
  success = 'success',
  warning = 'warning',
  error = 'error',
}

export type NotificationType = `${NotificationTypes}`;
