import { HttpService } from '@/services/index';
import { ApiResponse, ChapterListItem, Course, CourseChapter, CourseChapterCreation, PublicationStatus } from '@/types';

export default class ApiService {
  public static async fetchChapters(title: string): Promise<ChapterListItem[]> {
    const query = { title, limit: 3, select: ['title', 'id', 'status', 'type'] };
    const list: ChapterListItem[] = [];
    const responses = await Promise.all([
      HttpService.get<ApiResponse<{ title: string; _id: string; status: PublicationStatus }[]>>('/manage/units', query),
      HttpService.get<ApiResponse<{ title: string; _id: string; status: PublicationStatus }[]>>(
        '/manage/exercises',
        query,
      ),
    ]);
    if (responses && responses[0].data && responses[0]?.data?.success === true) {
      const { data } = responses[0].data;
      data.forEach(({ _id, title: label, status }) => {
        list.push({ value: _id, label, type: 'Unit', status });
      });
    }
    if (responses && responses[1].data && responses[1]?.data?.success === true) {
      const { data } = responses[1].data;
      data.forEach(({ _id, title: label, status }) => {
        list.push({ value: _id, label, type: 'Exercise', status });
      });
    }
    return list;
  }

  public static async fetchCourse(courseId: string): Promise<Course> {
    const response = await HttpService.get<ApiResponse<Course>>(`/manage/courses/${courseId}`);
    return response.data.data;
  }

  public static async fetchCourseCurriculum(courseId: string): Promise<CourseChapter[]> {
    const response = await HttpService.get<ApiResponse<Course>>(`/manage/courses/${courseId}`);
    return response.data.data.curriculum;
  }

  public static async addChapter(courseId: string, chapter: CourseChapterCreation): Promise<CourseChapter[]> {
    const response = await HttpService.post<ApiResponse<CourseChapter[]>>(
      `/manage/courses/${courseId}/chapters`,
      chapter,
    );
    return response.data.data;
  }

  public static async updateChapter(courseId: string, chapter: CourseChapterCreation): Promise<CourseChapter[]> {
    const response = await HttpService.patch<ApiResponse<CourseChapter[]>>(
      `/manage/courses/${courseId}/chapters`,
      chapter,
    );
    return response.data.data;
  }

  public static async removeChapter(courseId: string, chapterId: string): Promise<CourseChapter[]> {
    const response = await HttpService.delete<ApiResponse<CourseChapter[]>>(`/manage/courses/${courseId}/chapters`, {
      ids: [chapterId],
    });
    return response.data.data;
  }
}
