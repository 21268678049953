import { model, Model, prop } from 'mobx-keystone';

@model('RootStore/ViewStore')
export default class ViewStore extends Model({
  courseTabsActive: prop(true).withSetter(),
  exerciseTabsActive: prop(true).withSetter(),
  questionTabsActive: prop(true).withSetter(),
  unitTabsActive: prop(true).withSetter(),
  userTabsActive: prop(true).withSetter(),
  showLoginDialog: prop(false).withSetter(),
  showMobileSidebar: prop(false).withSetter(),
}) {}
