import { getSnapshot, model, Model, modelAction, prop } from 'mobx-keystone';
import i18n from 'i18next';
import { isEqual, omit } from 'lodash';
import { reaction } from 'mobx';

import { defaultSearch, searchMinLength } from '@/constants';
import { Question, QuestionResponse, QuestionsResponse } from '@/types';
import { SearchModel, StoreStatusModel } from '@/models';
import { ErrorService, NotificationService, UtilitiesService } from '@/services';

import { HttpService } from '../services';

const search = { ...defaultSearch, order: '-createdAt', termFields: ['title', 'question'] };

@model('RootStore/QuestionStore')
export default class UserStore extends Model({
  questions: prop<Question[]>(() => []),
  questionsSearch: prop<SearchModel>(() => new SearchModel(search)),
  questionSelected: prop<Question | undefined>(() => undefined).withSetter(),
  storeStatus: prop<StoreStatusModel>(() => new StoreStatusModel({})),
}) {
  @modelAction
  addQuestions = (questions: Question[], total?: number) => {
    this.questions = questions;
    if (typeof total !== 'undefined') {
      this.questionsSearch.total = total;
      // If less items than current page index, reset current page
      if (this.questionsSearch.total < this.questionsSearch.page * this.questionsSearch.limit) {
        this.questionsSearch.page = 0;
      }
    }
  };

  async getQuestions() {
    this.storeStatus.setLoading('getQuestions');
    try {
      const response = await HttpService.get<QuestionsResponse>(
        '/manage/questions',
        UtilitiesService.getSearchQueryString(this.questionsSearch),
      );
      const { data, total, success } = response.data;
      if (data && success) {
        this.addQuestions(data, total);
        this.storeStatus.setLoaded('getQuestions');
        return;
      }
    } catch (e) {
      ErrorService.handleError(e);
    }
    NotificationService.error(i18n.t('errors.failedToLoad'));
    this.storeStatus.setLoaded('getQuestions');
  }

  async retrieveAndSelectQuestion(id: string): Promise<boolean> {
    this.storeStatus.setLoading('retrieveAndSelectQuestion');
    this.setQuestionSelected(undefined);
    try {
      const response = await HttpService.get<QuestionResponse>(`/manage/questions/${id}`);
      const { data, success } = response.data;
      if (data && success) {
        this.setQuestionSelected(data);
        this.storeStatus.setLoaded('retrieveAndSelectQuestion');
        return true;
      }
    } catch (e) {
      ErrorService.handleError(e);
    }
    NotificationService.error(i18n.t('errors.failedToLoad'));
    this.storeStatus.setLoaded('retrieveAndSelectQuestion');
    return false;
  }

  async createQuestion(item: Partial<Question>) {
    this.storeStatus.setLoading('createQuestion');
    try {
      const response = await HttpService.post<QuestionResponse>('/manage/questions', item);
      const { data, success } = response.data;
      if (data && success) {
        NotificationService.success(i18n.t('success.created'));
        this.storeStatus.setLoaded('createQuestion');
        return;
      }
    } catch (e) {
      ErrorService.handleError(e);
    }
    NotificationService.error(i18n.t('errors.failedToSave'));
    this.storeStatus.setLoaded('createQuestion');
  }

  async updateSelectedQuestion(changes: Partial<Question>) {
    this.storeStatus.setLoading('updateSelectedQuestion');
    if (!this.questionSelected) throw Error('No question selected');
    try {
      const { id } = this.questionSelected;
      const response = await HttpService.patch<QuestionResponse>(`/manage/questions/${id}`, changes);
      const { data, success } = response.data;
      if (data && success) {
        this.setQuestionSelected(data);
        NotificationService.success(i18n.t('success.updated'));
        this.storeStatus.setLoaded('updateSelectedQuestion');
        return;
      }
    } catch (e) {
      ErrorService.handleError(e);
    }
    NotificationService.error(i18n.t('errors.failedToSave'));
    this.storeStatus.setLoaded('updateSelectedQuestion');
  }

  onAttachedToRootStore(): () => void {
    const questionSearchReaction = reaction(
      () => getSnapshot(this.questionsSearch),
      () => {
        if (
          !this.questionsSearch.term ||
          this.questionsSearch.term.length === 0 ||
          this.questionsSearch.term.length >= searchMinLength
        ) {
          this.getQuestions().catch(console.error);
        }
      },
      {
        // Only return unequal if anything but total changed on searchModel
        equals: (a, b) => isEqual(omit(a, 'total'), omit(b, 'total')),
      },
    );

    return () => {
      questionSearchReaction();
    };
  }
}
