import JwtDecode from 'jwt-decode';
import { DateTime } from 'luxon';
import ms from 'ms';

import { AccessTokenResponse, TokenPayload } from '@/types';

import HttpService from './HttpService';
import LogService from './LogService';

const accessTokenExpiry = process.env.NEXT_PUBLIC_ACCESS_TOKEN_EXPIRY;

if (!accessTokenExpiry) {
  throw new Error('NEXT_PUBLIC_ACCESS_TOKEN_EXPIRY is missing');
}

const accessTokenExpiryInSec = ms(accessTokenExpiry) / 1000;

class AuthService {
  /**
   * Duration to refresh the access token before expiration in seconds
   */
  public static accessTokenRefreshBeforeInSec = 5 * 60;

  /**
   * Get Access token refresh interval in seconds
   */
  public static getAccessTokenRefreshIntervalInSec(): number {
    if (accessTokenExpiryInSec <= this.accessTokenRefreshBeforeInSec) {
      throw new Error('accessTokenExpiryInSec has to be greater than accessTokenRefreshBeforeInSec');
    }
    return accessTokenExpiryInSec - this.accessTokenRefreshBeforeInSec;
  }

  /**
   * Get actual token expiration date
   */
  public static getActualExpirationDateFromAccessToken(token: string): DateTime {
    const payload = AuthService.getPayloadFromToken(token);
    return DateTime.fromSeconds(payload.exp);
  }

  /**
   * Get token expiration date minus refresh rate + buffer (to make sure one refresh is possible)
   */
  public static getReducedExpirationDateFromToken(token: string): DateTime {
    const payload = AuthService.getPayloadFromToken(token);
    const { accessTokenRefreshBeforeInSec } = AuthService;
    return DateTime.fromSeconds(payload.exp).minus({
      seconds: accessTokenRefreshBeforeInSec + 10,
    });
  }

  /**
   * Is token expired
   */
  public static isTokenExpired(token: string): boolean {
    const expiresDateTime = AuthService.getActualExpirationDateFromAccessToken(token);
    const expiresReducedDateTime = AuthService.getReducedExpirationDateFromToken(token);
    const expired = expiresReducedDateTime < DateTime.local();
    let info = `[AS] Access token expires at: ${expiresDateTime.toString()}; Refresh at: ${expiresReducedDateTime.toString()}`;
    if (expired) info = `[AS] Access token expired at: ${expiresDateTime.toString()}`;
    LogService.debug(info);
    return expired;
  }

  /**
   * Get payload from access token
   */
  public static getPayloadFromToken(token: string): TokenPayload {
    return JwtDecode(token);
  }

  /**
   * Retrieve a new access token
   */
  public static async retrieveAccessToken(refreshToken: string): Promise<string | undefined> {
    const response = await HttpService.post<AccessTokenResponse>('/refresh-access-token', { refreshToken });
    if (response.data.data?.accessToken) {
      return response.data.data.accessToken;
    }
    return undefined;
  }
}

export default AuthService;
