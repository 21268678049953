import { Model, model, prop } from 'mobx-keystone';

import type AccountStore from './AccountStore';
import type CourseStore from './CourseStore';
import type ExerciseStore from './ExerciseStore';
import type QuestionStore from './QuestionStore';
import type UnitStore from './UnitStore';
import type UserStore from './UserStore';
import type ViewStore from './ViewStore';

@model('RootStore')
class RootStore extends Model({
  AccountStore: prop<AccountStore>(),
  CourseStore: prop<CourseStore>(),
  ExerciseStore: prop<ExerciseStore>(),
  QuestionStore: prop<QuestionStore>(),
  UserStore: prop<UserStore>(),
  UnitStore: prop<UnitStore>(),
  ViewStore: prop<ViewStore>(),
}) {}

export default RootStore;
