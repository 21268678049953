export enum StoreStatuses {
  initializing = 'initializing',
  created = 'created',
  waiting = 'waiting',
  attached = 'attached',
  loading = 'loading',
  loaded = 'loaded',
  error = 'error',
}

export type StoreStatus = `${StoreStatuses}`;
