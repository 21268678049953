export const publicUrl = process.env.NEXT_PUBLIC_URL || '';
export const apiUrl = process.env.NEXT_PUBLIC_API_URL || '';
export const environment = process.env.NEXT_PUBLIC_ENVIRONMENT || '';
// export const packageVersion = process.env.NEXT_PUBLIC_PACKAGE_VERSION || '';
// export const packageName = process.env.NEXT_PUBLIC_PACKAGE_NAME || '';
// export const commitHash = process.env.NEXT_PUBLIC_COMMIT_HASH || '';

if (publicUrl === '') throw new Error('NEXT_PUBLIC_URL is missing.');
if (apiUrl === '') throw new Error('NEXT_PUBLIC_API_URL is missing.');
if (environment === '') throw new Error('NEXT_PUBLIC_ENVIRONMENT is missing.');
// if (packageVersion === '') throw new Error('NEXT_PUBLIC_PACKAGE_VERSION is missing.');
// if (packageName === '') throw new Error('NEXT_PUBLIC_PACKAGE_NAME is missing.');
// if (commitHash === '') throw new Error('NEXT_PUBLIC_COMMIT_HASH is missing.');

// export const releaseName = `${packageName}@${packageVersion}`;
// export const commitHashShort = commitHash.substring(0, 7);
export const isDevelopment = environment === 'development';
