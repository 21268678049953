import { css } from '@emotion/react';
import Container from '@mui/material/Container';
import LinearProgress from '@mui/material/LinearProgress';
import { useNProgress } from '@tanem/react-nprogress';
import React from 'react';

const barStyle = (animationDuration: number) =>
  css({
    transitionDuration: `${animationDuration}ms`,
  });

const containerStyle = (animationDuration: number, isFinished: boolean) =>
  css({
    opacity: isFinished ? 0 : 1,
    pointerEvents: 'none',
    transition: `opacity ${animationDuration}ms linear`,
  });

function Progress() {
  const { animationDuration, isFinished, progress } = useNProgress({
    isAnimating: true,
  });

  return (
    <Container classes={{ root: containerStyle(animationDuration, isFinished).styles }}>
      <LinearProgress
        classes={{ bar1Determinate: barStyle(animationDuration).styles }}
        value={progress * 100}
        variant="determinate"
      />
    </Container>
  );
}

export default Progress;
