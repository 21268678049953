import Cookies, { CookieAttributes } from 'js-cookie';

export default class CookieService {
  static get<T>(name: string): T | undefined {
    const cookie = Cookies.get(name);

    return cookie ? (JSON.parse(cookie) as T) : undefined;
  }

  static set<T>(name: string, value: string | T, options?: CookieAttributes): string | undefined {
    return Cookies.set(name, JSON.stringify(value), options);
  }

  static remove(name: string, options?: CookieAttributes): void {
    Cookies.remove(name, options);
  }
}
