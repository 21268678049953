import { useContext } from 'react';

import RootStore from './RootStore';
import { StoreContext, StoreProvider } from './StoreContext';

function useStore(): RootStore {
  const contextStore = useContext(StoreContext);

  if (
    !contextStore ||
    !contextStore.AccountStore ||
    !contextStore.CourseStore ||
    !contextStore.ExerciseStore ||
    !contextStore.QuestionStore ||
    !contextStore.UserStore ||
    !contextStore.UnitStore ||
    !contextStore.ViewStore
  ) {
    // this is especially useful in TypeScript so you don't need to be checking for null all the time
    throw new Error('Store must be used within a StoreProvider.');
  }

  return contextStore;
}

export { RootStore, StoreProvider, useStore };
