import { computed } from 'mobx';
import { model, Model, modelAction, prop } from 'mobx-keystone';
import { signOut } from 'next-auth/react';
import i18n from 'i18next';

import { bookingSessionCookieName } from '@/constants';
import { StoreStatusModel } from '@/models';
import { HttpService, ErrorService, NotificationService, CookieService } from '@/services';
import { CurrentAccount, CurrentAccountAndPassword, UserMeResponse, UserMeUpdateResponse } from '@/types';

@model('RootStore/AccountStore')
export default class AccountStore extends Model({
  currentAccount: prop<CurrentAccount | undefined>(() => undefined).withSetter(),
  storeStatus: prop<StoreStatusModel>(() => new StoreStatusModel({})),
  redirectUrl: prop<string | undefined>(undefined),
}) {
  @computed
  get isLoggedIn(): boolean {
    return !!this.currentAccount;
  }

  @modelAction
  setRedirectUrl = (url: string | undefined): void => {
    this.redirectUrl = url;
  };


  @modelAction
  logoutCurrentUser = async (): Promise<void> => {
    this.setRedirectUrl(undefined);
    HttpService.unsetAuthorizationHeader();
    this.setCurrentAccount(undefined);
    CookieService.remove(bookingSessionCookieName);
    await signOut({ callbackUrl: '/login' });
  };

  @modelAction
  setCurrentAccount = (account: CurrentAccount | undefined): void => {
    this.currentAccount = account;
    this.storeStatus.setLoaded('setCurrentAccount');
  };

  async retrieveAndSetCurrentAccount(): Promise<boolean> {
    this.storeStatus.setLoading('retrieveAndSetCurrentAccount');
    this.setCurrentAccount(undefined);
    try {
      const response = await HttpService.get<UserMeResponse>(`/users/me`);
      const { data, success } = response.data;
      if (data && success) {
        this.setCurrentAccount(data);
        this.storeStatus.setLoaded('retrieveAndSetCurrentAccount');
        return true;
      }
    } catch (e) {
      ErrorService.handleError(e);
    }
    NotificationService.error(i18n.t('errors.failedToLoad'));
    this.storeStatus.setLoaded('retrieveAndSetCurrentAccount');
    return false;
  }

  async updateCurrentAccount(changes: Partial<CurrentAccountAndPassword>) {
    this.storeStatus.setLoading('updateCurrentAccount');
    try {
      const response = await HttpService.patch<UserMeUpdateResponse>(`/users/me`, changes);
      const { data, success } = response.data;
      if (data && success) {
        this.setCurrentAccount(data.user);
        this.storeStatus.setLoaded('updateCurrentAccount');
        return;
      }
    } catch (e) {
      ErrorService.handleError(e);
    }
    NotificationService.error(i18n.t('errors.failedToSave'));
    this.storeStatus.setLoaded('updateCurrentAccount');
  }
}
