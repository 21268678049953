import { Components } from '@mui/material/styles';

const MuiCssBaseline: Components['MuiCssBaseline'] = {
  styleOverrides: `
    body {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }

    body,
    #__next {
      display: flex;
      flex-direction: column;
    }

    #__next {
      flex: 1;
      min-height: 0;
    }

    main {
      min-height: 0;
    }

    fieldset {
      border: 0;
      padding: 0;
      margin: 0;
    }

    fieldset:disabled input, fieldset:disabled legend span, fieldset:disabled label span {
      color: rgba(0, 0, 0, 0.5);
      cursor: wait;
    }
  `,
};

export default MuiCssBaseline;
