import { computed } from 'mobx';
import { Model, model, modelAction, prop } from 'mobx-keystone';

import { StoreStatus } from '@/types';

@model('RootStore/StatusModel')
export default class StoreStatusModel extends Model({
  status: prop<StoreStatus>('initializing'),
  loading: prop<string[]>(() => []),
  loaded: prop<string[]>(() => []),
}) {
  @computed
  get isCreated(): boolean {
    return this.status !== 'initializing' && this.status !== 'error';
  }

  @computed
  get isAttached(): boolean {
    return this.status !== 'initializing' && this.status !== 'created' && this.status !== 'waiting';
  }

  isLoading = (loader?: string): boolean => {
    if (loader) {
      return this.loading.indexOf(loader) > -1;
    }
    return this.status === 'loading' || this.loading.length > 0;
  };

  isLoaded = (loader?: string): boolean => {
    if (loader) {
      return this.loaded.indexOf(loader) > -1;
    }
    return this.status === 'loaded' && !this.loading.length;
  };

  @computed
  get hasError(): boolean {
    return this.status === 'error';
  }

  @modelAction
  setCreated = (): void => {
    this.status = 'created';
  };

  @modelAction
  setAttached = (): void => {
    this.status = 'attached';
  };

  @modelAction
  setLoading = (loader?: string): void => {
    if (loader) {
      // Add loading
      if (this.loading.indexOf(loader) === -1) {
        this.loading.push(loader);
      }
      // Remove loaded
      if (this.loaded.indexOf(loader) > -1) {
        this.loaded = this.loaded.filter((item) => item !== loader);
      }
    } else {
      this.status = 'loading';
    }
  };

  @modelAction
  setLoaded = (loader?: string): void => {
    if (loader) {
      // Remove loading
      if (this.loading.indexOf(loader) > -1) {
        this.loading = this.loading.filter((item) => item !== loader);
      }
      // Add loaded
      if (this.loaded.indexOf(loader) === -1) {
        this.loaded.push(loader);
      }
    } else {
      this.status = 'loaded';
    }
  };

  @modelAction
  setError = (): void => {
    this.status = 'error';
  };

  onInit(): void {
    this.setCreated();
  }
}
