import { enableStaticRendering } from 'mobx-react';
import { applySnapshot, connectReduxDevTools, isRootStore, registerRootStore, SnapshotOutOf } from 'mobx-keystone';
import remotedev from 'remotedev';

import { LogService, UtilitiesService } from '@/services';
import { appName } from '@/constants';

import AccountStore from './AccountStore';
import CourseStore from './CourseStore';
import ExerciseStore from './ExerciseStore';
import QuestionStore from './QuestionStore';
import RootStore from './RootStore';
import UnitStore from './UnitStore';
import UserStore from './UserStore';
import ViewStore from './ViewStore';

enableStaticRendering(typeof window === 'undefined');

let store: RootStore | null = null;

const initStore = (snapshot?: SnapshotOutOf<RootStore>): RootStore => {
  const newStore =
    store ??
    new RootStore({
      AccountStore: new AccountStore({}),
      CourseStore: new CourseStore({}),
      ExerciseStore: new ExerciseStore({}),
      QuestionStore: new QuestionStore({}),
      UnitStore: new UnitStore({}),
      UserStore: new UserStore({}),
      ViewStore: new ViewStore({}),
    });

  // If your page has Next.js data fetching methods that use a Mobx store, it will
  // get hydrated here, check `pages/ssg.tsx` and `pages/ssr.tsx` for more details
  if (snapshot) {
    applySnapshot(newStore, snapshot);
  }

  // For SSG and SSR always create a new store
  if (typeof window === 'undefined') return newStore;

  // Register as RootStore
  if (!isRootStore(newStore)) {
    registerRootStore(newStore);
  }

  // Create the store once in the client
  if (!store) store = newStore;

  // Dev tools
  // env variable is only available when using `expo start` not in build
  const { NEXT_PUBLIC_DEV_TOOLS } = process.env;

  const useDevTools =
    NEXT_PUBLIC_DEV_TOOLS !== 'undefined' && NEXT_PUBLIC_DEV_TOOLS === 'true' && UtilitiesService.isDevelopment();
  // eslint-disable-next-line no-console
  LogService.debug('Use react devtools', useDevTools);

  if (useDevTools) {
    const connection = remotedev.connectViaExtension({
      name: appName,
    });
    connectReduxDevTools(remotedev, connection, store);
  }

  return store;
};

export default initStore;
