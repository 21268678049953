import { ContentType } from './Exercise';
import { Language } from './Generic';
import { PublicationStatus } from './PublicationStatus';

export enum QuestionEffects {
  showAnswersSequentially = 'showAnswersSequentially',
}

export type QuestionEffect = `${QuestionEffects}`;

export enum QuestionTypes {
  mc = 'mc',
  mctf = 'mctf',
  sc = 'sc',
  sq = 'sq',
  tf = 'tf',
}

export type QuestionType = `${QuestionTypes}`;

export enum QuestionAnswerReferenceTypes {
  book = 'book',
  text = 'text',
}

export type QuestionAnswerReferenceType = `${QuestionAnswerReferenceTypes}`;

export interface QuestionExplanationReference {
  // id: string; // -> References a book or text
  pages?: string[];
  chapters?: string[];
  paragraphs?: string[];
  type: QuestionAnswerReferenceType;
}

export type QuestionExplanation = {
  explanation?: string;
  index: number;
  references?: QuestionExplanationReference[];
};

export interface QuestionExplanationBlock {
  type: ContentType;
  text?: string;
  image?: string;
  title?: string;
  references?: QuestionExplanationReference[];
}

export type QuestionAnswer = {
  index: number;
  answer: string;
  // hint?: string;
};

export type Question = {
  answers: QuestionAnswer[];
  createdAt: string;
  createdBy: string;
  description?: string;
  effect?: QuestionEffect;
  explanationBlocks: QuestionExplanationBlock[];
  explanations: QuestionExplanation[];
  id: string;
  // internalComments?: string[];
  language: Language;
  question: string;
  solution: string[];
  sourceId: string;
  status: PublicationStatus;
  tags?: string[];
  type: QuestionType;
  updatedAt: string;
  updatedBy?: string;
};
