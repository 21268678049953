import { appName, searchMinLength } from '@/constants';
import { SearchModel } from '@/models';
import { SearchParams } from '@/types';

const publicUrl = process.env.NEXT_PUBLIC_URL;

if (!publicUrl) throw new Error('NEXT_PUBLIC_URL is missing.');

export default class UtilitiesService {
  public static getInitials = (name = ''): string =>
    name
      .replace(/\s+/, ' ')
      .split(' ')
      .slice(0, 2)
      .map((v) => v && v[0].toUpperCase())
      .join('');

  public static getPageTitle = (pageName: string): string => `${pageName} | ${appName}`;

  public static getEnvironment = (): string | undefined => process.env.NEXT_PUBLIC_ENVIRONMENT;

  public static isDevelopment = (): boolean => UtilitiesService.getEnvironment() === 'development';

  /**
   * Capitalize first letter
   * @param string
   */
  public static capitalizeFirstLetter(string: string): string {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  /**
   * Get search query string
   * @param search
   */
  public static getSearchQueryString(search: SearchModel): SearchParams {
    const { customParams, page, limit, order, term, termFields } = search;

    return {
      limit,
      offset: page * limit,
      ...customParams,
      ...(order && order !== '' && { order }),
      ...(term && term !== '' && term.length >= searchMinLength && { term }),
      ...(termFields.length && { termFields }),
    };
  }
}
