import * as Sentry from '@sentry/nextjs';
import axios from 'axios';
import i18n from 'i18next';

import { NotificationService } from '@/services';

export default class ErrorService {
  public static handleError(error: unknown, message?: string): void {
    let errorMessage = i18n.t('errors.unknown');
    console.error(error, message);
    Sentry.captureException(new Error(errorMessage));

    if (message) {
      errorMessage = message;
    } else if (axios.isAxiosError(error)) {
      if (error.response?.data.message) {
        errorMessage = error.response.data.message;
      } else if (error?.response?.data.error) {
        errorMessage = error.response.data.error;
      }
    }

    NotificationService.error(errorMessage);
  }
}
