import { observer } from 'mobx-react';
import { useSession } from 'next-auth/react';
import { ReactElement, useEffect } from 'react';

import { HttpService, LogService } from '@/services';
import { useStore } from '@/stores';
import LoadingScreen from '@/components/shared/LoadingScreen';

type Props = {
  children: ReactElement;
};

function AccountWrapper({ children }: Props) {
  const { data: session, status: sessionStatus, } = useSession();
  const { AccountStore } = useStore();

  useEffect(() => {
    if (sessionStatus === 'authenticated' && session) {
      const { accessToken } = session;
      // Set access token in axios instance
      HttpService.setAuthorizationHeader(accessToken);
      // Set current user session
      AccountStore.setCurrentAccount(session.user);
      LogService.debug(`[AS] Next Session expires at: ${session.expires}`);
    }
  }, [AccountStore, session, sessionStatus]);

  if (sessionStatus === 'loading'
    || (sessionStatus === 'authenticated' && !AccountStore.storeStatus.isLoaded('setCurrentAccount'))
  ) return <LoadingScreen />;

  // Make sure that we only render children after the useEffect above has run and headers were set
  return children;
}

export default observer(AccountWrapper);
