import { getSnapshot, model, Model, modelAction, prop } from 'mobx-keystone';
import i18n from 'i18next';
import { isEqual, omit } from 'lodash';
import { reaction } from 'mobx';

import { defaultSearch } from '@/constants';
import {
  DeleteResponse,
  User,
  UserCreation,
  UserResponse,
  UserSession,
  UserSessionsResponse,
  UsersResponse,
} from '@/types';
import { SearchModel, StoreStatusModel } from '@/models';
import { ErrorService, NotificationService, UtilitiesService } from '@/services';

import { HttpService } from '../services';

const search = { ...defaultSearch, order: 'createdAt' };

@model('RootStore/UserStore')
export default class UserStore extends Model({
  users: prop<User[]>(() => []),
  userSessions: prop<UserSession[] | undefined>(undefined),
  usersSearch: prop<SearchModel>(() => new SearchModel(search)),
  userSelected: prop<User | undefined>(() => undefined).withSetter(),
  storeStatus: prop<StoreStatusModel>(() => new StoreStatusModel({})),
}) {
  @modelAction
  addUsers = (users: User[], total?: number) => {
    this.users = users;
    if (typeof total !== 'undefined') {
      this.usersSearch.total = total;
      // If less items than current page index, reset current page
      if (this.usersSearch.total < this.usersSearch.page * this.usersSearch.limit) {
        this.usersSearch.page = 0;
      }
    }
  };

  @modelAction
  addUserSessions = (sessions?: UserSession[]) => {
    this.userSessions = sessions;
  };

  async createUser(user: UserCreation): Promise<boolean> {
    this.storeStatus.setLoading('createUser');
    try {
      const response = await HttpService.post<UserResponse>('/users', user);
      const { data, success } = response.data;
      if (data && success) {
        this.storeStatus.setLoaded('createUser');
        NotificationService.success(i18n.t('users.create.createdSuccessfully'));
        return true;
      }
    } catch (e) {
      ErrorService.handleError(e);
    }
    NotificationService.error(i18n.t('errors.failedToSave'));
    this.storeStatus.setLoaded('createUser');
    return false;
  }

  async getUsers() {
    this.storeStatus.setLoading('getUsers');
    try {
      const response = await HttpService.get<UsersResponse>(
        '/users',
        UtilitiesService.getSearchQueryString(this.usersSearch),
      );
      const { data, success, total } = response.data;
      if (data && success) {
        this.addUsers(data, total);
        this.storeStatus.setLoaded('getUsers');
        return;
      }
    } catch (e) {
      ErrorService.handleError(e);
    }
    NotificationService.error(i18n.t('errors.failedToLoad'));
    this.storeStatus.setLoaded('getUsers');
  }

  async retrieveAndSelectUser(id: string): Promise<boolean> {
    this.storeStatus.setLoading('retrieveAndSelectUser');
    this.setUserSelected(undefined);
    try {
      const response = await HttpService.get<UserResponse>(`/users/${id}`);
      const { data, success } = response.data;
      if (data && success) {
        this.setUserSelected(data);
        this.storeStatus.setLoaded('retrieveAndSelectUser');
        return true;
      }
    } catch (e) {
      ErrorService.handleError(e);
    }
    NotificationService.error(i18n.t('errors.failedToLoad'));
    this.storeStatus.setLoaded('retrieveAndSelectUser');
    return false;
  }

  async getUserSessions(userId: string) {
    this.storeStatus.setLoading('getUserSessions');
    try {
      const response = await HttpService.get<UserSessionsResponse>(`/users/${userId}/sessions`);
      const { data, success } = response.data;
      if (data && success) {
        this.addUserSessions(data);
        this.storeStatus.setLoaded('getUserSessions');
        return;
      }
    } catch (e) {
      ErrorService.handleError(e);
    }
    NotificationService.error(i18n.t('errors.failedToLoad'));
    this.storeStatus.setLoaded('getUserSessions');
  }

  async removeUserSession(userSessionId: string): Promise<boolean> {
    this.storeStatus.setLoading('removeUserSession');
    try {
      const response = await HttpService.delete<DeleteResponse>('/users/sessions', { ids: [userSessionId] });
      const { data, success } = response.data;
      if (data.ok === 1 && data.deletedCount === 1 && success) {
        const filtered = this.userSessions?.filter(({ id }) => id !== userSessionId);
        this.addUserSessions(filtered);
        this.storeStatus.setLoaded('removeUserSession');
        return true;
      }
    } catch (e) {
      ErrorService.handleError(e);
    }
    NotificationService.error(i18n.t('errors.failedToDelete'));
    this.storeStatus.setLoaded('removeUserSession');
    return false;
  }

  async deactivateUserCourseMembership(userId: string, ids: string[]) {
    this.storeStatus.setLoading('deactivateUserCourseMembership');
    try {
      const response = await HttpService.patch<UserResponse>(`/users/${userId}/deactivate-memberships`, { ids });
      const { data, success } = response.data;
      if (data && success) {
        this.setUserSelected(data);
        this.storeStatus.setLoaded('deactivateUserCourseMembership');
        return;
      }
    } catch (e) {
      ErrorService.handleError(e);
    }
    NotificationService.error(i18n.t('errors.failedToSave'));
    this.storeStatus.setLoaded('deactivateUserCourseMembership');
  }

  async addOrUpdateUserCourseMembership(userId: string, memberships: {
    courseMembership: string;
    newRepetitions?: number;
  }[],) {
    this.storeStatus.setLoading('addOrUpdateUserCourseMembership');
    try {
      const response = await HttpService.patch<UserResponse>(`/users/${userId}/memberships`, { memberships });
      const { data, success } = response.data;
      if (data && success) {
        this.setUserSelected(data);
        this.storeStatus.setLoaded('addOrUpdateUserCourseMembership');
        return;
      }
    } catch (e) {
      ErrorService.handleError(e);
    }
    NotificationService.error(i18n.t('errors.failedToSave'));
    this.storeStatus.setLoaded('addOrUpdateUserCourseMembership');
  }

  onAttachedToRootStore(): () => void {
    const userSearchReaction = reaction(
      () => getSnapshot(this.usersSearch),
      () => {
        if (!this.usersSearch.term || this.usersSearch.term.length === 0 || this.usersSearch.term.length >= 3) {
          this.getUsers().catch(console.error);
        }
      },
      {
        // Only return unequal if anything but total changed on searchModel
        equals: (a, b) => isEqual(omit(a, 'total'), omit(b, 'total')),
      },
    );

    return () => {
      userSearchReaction();
    };
  }
}
