export enum UserRoles {
  'customer' = 'customer',
  'manager' = 'manager',
  'admin' = 'admin',
}

export type UserRole = `${UserRoles}`;

export type UCMRepetition = {
  run: number;
  remaining: number;
  status: 'submitted' | 'active';
};

export type UserCourseMembership = {
  _id: string;
  courseMembership: string;
  createdAt: string;
  updatedAt: string;
  expiresAt?: string; // for specificDate courseMemberships
  repetitions: UCMRepetition;
  status: 'active' | 'expired';
};

export type UserSession = {
  id: string;
  browserFingerprint: string;
  createdAt?: string;
  createdBy?: string;
  lastConnectedAt: string;
  lastDisconnectedAt: string;
  socketConnected: boolean;
  socketId: string;
  status: 'active' | 'deactivated';
  title: string;
  updatedAt?: string;
  updatedBy?: string;
};

export type User = {
  id: string;
  role: UserRole;
  wpUserId?: number;
  email: string;
  firstName: string;
  lastName: string;
  memberships: UserCourseMembership[];
  createdAt: string;
  updatedAt: string;
  lastLoginAt: string;
};

export type UserCreation = {
  role: UserRole;
  email: string;
  firstName: string;
  lastName: string;
  password: string;
};
