import { ListOrderOption } from '@/types';

export const titleOrderOptions: ListOrderOption[] = [
  {
    value: 'title',
    label: 'order.byTitleAsc',
  },
  {
    value: '-title',
    label: 'order.byTitleDesc',
  },
];

export const updatedAtOrderOptions: ListOrderOption[] = [
  {
    value: '-updatedAt',
    label: 'order.byUpdatedAtDesc',
  },
  {
    value: 'updatedAt',
    label: 'order.byUpdatedAtAsc',
  },
];

export const createdAtOrderOptions: ListOrderOption[] = [
  {
    value: '-createdAt',
    label: 'order.byCreatedAtDesc',
  },
  {
    value: 'createdAt',
    label: 'order.byCreatedAtAsc',
  },
];

export const statusOrderOptions: ListOrderOption[] = [
  {
    value: 'status',
    label: 'order.byStatusAsc',
  },
  {
    value: '-status',
    label: 'order.byStatusDesc',
  },
];

export const defaultOrderOptions: ListOrderOption[] = [
  ...titleOrderOptions,
  ...updatedAtOrderOptions,
  ...createdAtOrderOptions,
];
