import { AxiosPromise, AxiosRequestConfig } from 'axios';

import { Ids } from '@/types';
import axiosInstance from '@/libs/axios';

export default class HttpService {
  /**
   * DELETE request
   */
  public static delete<T>(url: string, data: Ids, config: AxiosRequestConfig | undefined = undefined): AxiosPromise<T> {
    return axiosInstance.delete<T>(url, { ...config, data });
  }

  /**
   * GET request
   */
  public static get<T>(
    url: string,
    params?: unknown,
    config: AxiosRequestConfig | undefined = undefined,
  ): AxiosPromise<T> {
    return axiosInstance.get<T>(url, {
      ...config,
      params,
    });
  }

  /**
   * POST request
   */
  public static post<T>(
    url: string,
    data?: unknown,
    config: AxiosRequestConfig | undefined = undefined,
  ): AxiosPromise<T> {
    return axiosInstance.post<T>(url, data, config);
  }

  /**
   * PATCH request
   */
  public static patch<T>(
    url: string,
    data?: unknown,
    config: AxiosRequestConfig | undefined = undefined,
  ): AxiosPromise<T> {
    return axiosInstance.patch<T>(url, data, config);
  }

  /**
   * PUT request
   */
  public static put<T>(
    url: string,
    data: unknown,
    config: AxiosRequestConfig | undefined = undefined,
  ): AxiosPromise<T> {
    return axiosInstance.put<T>(url, data, config);
  }

  /**
   * Set Authorization header
   */
  public static setAuthorizationHeader(accessToken: string): void {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    axiosInstance.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  }

  /**
   * Unset Authorization header
   */
  public static unsetAuthorizationHeader(): void {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    delete axiosInstance.defaults.headers.common.Authorization;
  }
}
