import { PublicationStatus } from './PublicationStatus';
import { ChapterAccess, Language } from './Generic';

export enum ExerciseShowResults {
  afterSubmission = 'afterSubmission',
  inResultsView = 'inResultsView',
}

export type ExerciseShowResult = `${ExerciseShowResults}`;

export enum ExerciseSelectQuestionsByList {
  'id' = 'id',
  'tag' = 'tag',
}

export type ExerciseSelectQuestionsBy = `${ExerciseSelectQuestionsByList}`;

export enum ExerciseRepetitions {
  always = 'always',
  never = 'never',
  perTestRun = 'perTestRun',
}

export type ExerciseRepetition = `${ExerciseRepetitions}`;

export type QuestionTag = {
  _id?: string;
  tag: string;
  quantity: number;
  testRun?: number;
};

export enum ContentTypes {
  text = 'text',
  image = 'image',
}

export type ContentType = `${ContentTypes}`;

export type ArticleParagraph = {
  type: ContentType;
  text: string;
  index: number;
};

export type Article = {
  authors: string[];
  paragraphs: ArticleParagraph[];
  references?: string[];
  title?: string;
  testRun?: number;
};

export type Exercise = {
  access: ChapterAccess;
  articles: Article[];
  course: string;
  createdAt: string;
  createdBy: string;
  description?: string;
  id: string;
  language: Language;
  questions?: string[];
  questionTags: QuestionTag[];
  repeatable: ExerciseRepetition;
  selectQuestionsBy: ExerciseSelectQuestionsBy;
  showResult: ExerciseShowResult;
  shuffleQuestions: boolean;
  slug: string;
  sourceId: string;
  status: PublicationStatus;
  tags?: string[];
  task: string;
  testRunSequences?: number;
  title: string;
  updatedAt: string;
  updatedBy?: string;
};
