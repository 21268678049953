import { isDevelopment } from '@/constants';

export default class LogService {
  public static debug = (...params: unknown[]): void => {
    if (isDevelopment) {
      // eslint-disable-next-line no-console
      console.log(...params);
    }
  };

  public static info = (...params: unknown[]): void => {
    if (isDevelopment) {
      // eslint-disable-next-line no-console
      console.info(...params);
    }
  };

  public static warning = (...params: unknown[]): void => {
    if (isDevelopment) {
      // eslint-disable-next-line no-console
      console.warn(...params);
    }
  };

  public static error = (...params: unknown[]): void => {
    if (isDevelopment) {
      console.error(...params);
    }
  };
}
