import { getSnapshot, model, Model, modelAction, prop } from 'mobx-keystone';
import i18n from 'i18next';
import { computed, reaction } from 'mobx';
import { isEqual, omit } from 'lodash';

import { defaultSearch, searchMinLength } from '@/constants';
import { Course, CourseMembership, CourseMembershipsResponse, CourseResponse, CoursesResponse } from '@/types';
import { SearchModel, StoreStatusModel } from '@/models';
import { ApiService, ErrorService, HttpService, NotificationService, UtilitiesService } from '@/services';

const search = { ...defaultSearch, order: '-createdAt' };

@model('RootStore/CourseStore')
export default class UserStore extends Model({
  courses: prop<Course[]>(() => []),
  courseMemberships: prop<CourseMembership[]>(() => []),
  coursesSearch: prop<SearchModel>(() => new SearchModel(search)),
  courseSelected: prop<Course | undefined>(() => undefined).withSetter(),
  storeStatus: prop<StoreStatusModel>(() => new StoreStatusModel({})),
}) {
  @computed
  get courseOptions(): { id: string; label: string }[] {
    if (!this.courses.length) return [];
    return this.courses.map((course) => ({
      id: course.id,
      label: course.title,
    }));
  }

  @modelAction
  addCourseMembership = (courseMembership: CourseMembership) => {
    this.courseMemberships.push(courseMembership);
  };

  async getCourseMemberships() {
    this.storeStatus.setLoading();
    const response = await HttpService.get<CourseMembershipsResponse>('/course-memberships?limit=100&order=-createdAt');
    const { data, success } = response.data;
    if (data && success) {
      data.forEach((courseMembership: CourseMembership) => {
        this.addCourseMembership(courseMembership);
      });
    }
    this.storeStatus.setLoaded();
  }

  @modelAction
  addCourses = (courses: Course[], total?: number) => {
    this.courses = courses;
    if (typeof total !== 'undefined') {
      this.coursesSearch.total = total;
      // If less items than current page index, reset current page
      if (this.coursesSearch.total < this.coursesSearch.page * this.coursesSearch.limit) {
        this.coursesSearch.page = 0;
      }
    }
  };

  async getCourses() {
    if (this.courses.length && this.coursesSearch.term && this.coursesSearch.term?.length < searchMinLength) return;
    this.storeStatus.setLoading('getCourses');
    try {
      const response = await HttpService.get<CoursesResponse>(
        '/manage/courses',
        UtilitiesService.getSearchQueryString(this.coursesSearch),
      );
      const { data, total, success } = response.data;
      if (data && success) {
        this.addCourses(data, total);
        this.storeStatus.setLoaded('getCourses');
        return;
      }
    } catch (e) {
      ErrorService.handleError(e);
    }
    NotificationService.error(i18n.t('errors.failedToLoad'));
    this.storeStatus.setLoaded('getCourses');
  }

  async retrieveAndSelectCourse(id: string): Promise<boolean> {
    this.storeStatus.setLoading('retrieveAndSelectCourse');
    this.setCourseSelected(undefined);
    try {
      const course = await ApiService.fetchCourse(id);
      this.setCourseSelected(course);
      this.storeStatus.setLoaded('retrieveAndSelectCourse');
      return true;
    } catch (e) {
      ErrorService.handleError(e);
    }
    NotificationService.error(i18n.t('errors.failedToLoad'));
    this.storeStatus.setLoaded('retrieveAndSelectCourse');
    return false;
  }

  async createCourse(item: Partial<Course>) {
    this.storeStatus.setLoading('createCourse');
    try {
      const response = await HttpService.post<CourseResponse>('/manage/courses', item);
      const { data, success } = response.data;
      if (data && success) {
        NotificationService.success(i18n.t('success.created'));
        this.storeStatus.setLoaded('createCourse');
        return;
      }
    } catch (e) {
      ErrorService.handleError(e);
    }
    NotificationService.error(i18n.t('errors.failedToSave'));
    this.storeStatus.setLoaded('createCourse');
  }

  async updateSelectedCourse(changes: Partial<Course>) {
    this.storeStatus.setLoading('updateSelectedCourse');
    if (!this.courseSelected) throw Error('No course selected');
    try {
      const { id } = this.courseSelected;
      const response = await HttpService.patch<CourseResponse>(`/manage/courses/${id}`, changes);
      const { data, success } = response.data;
      if (data && success) {
        this.setCourseSelected(data);
        NotificationService.success(i18n.t('success.updated'));
        this.storeStatus.setLoaded('updateSelectedCourse');
        return;
      }
    } catch (e) {
      ErrorService.handleError(e);
    }
    NotificationService.error(i18n.t('errors.failedToSave'));
    this.storeStatus.setLoaded('updateSelectedCourse');
  }

  onAttachedToRootStore(): () => void {
    const courseSearchReaction = reaction(
      () => getSnapshot(this.coursesSearch),
      () => {
        if (
          !this.coursesSearch.term ||
          this.coursesSearch.term.length === 0 ||
          this.coursesSearch.term.length >= searchMinLength
        ) {
          this.getCourses().catch(console.error);
        }
      },
      {
        // Only return unequal if anything but total changed on searchModel
        equals: (a, b) => isEqual(omit(a, 'total'), omit(b, 'total')),
      },
    );

    return () => {
      courseSearchReaction();
    };
  }
}
