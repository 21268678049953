import { Model, model, prop } from 'mobx-keystone';

@model('RootStore/SearchModel')
export default class SearchModel extends Model({
  customParams: prop<{ [key: string]: string | string[] | number } | undefined>(undefined).withSetter(),
  limit: prop<number>().withSetter(),
  loading: prop<boolean>().withSetter(),
  order: prop<string | undefined>(undefined).withSetter(),
  page: prop<number>().withSetter(),
  pagesLoaded: prop<number[]>(() => []).withSetter(),
  term: prop<string | undefined>(undefined).withSetter(),
  termFields: prop<string[]>(() => []).withSetter(),
  total: prop<number>().withSetter(),
}) {}
