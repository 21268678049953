import axios, { AxiosError } from 'axios';

import { ApiResponseError } from '@/types';

const apiUrl = process.env.NEXT_PUBLIC_API_URL;
const version = process.env.NEXT_PUBLIC_API_VERSION;

if (!apiUrl) {
  throw new Error('NEXT_PUBLIC_API_URL is missing');
}
if (!version) {
  throw new Error('NEXT_PUBLIC_API_VERSION is missing');
}


const axiosInstance = axios.create({
  baseURL: `${apiUrl}/${version}`,
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error: AxiosError<ApiResponseError>['response']) => Promise.reject(error),
);

export default axiosInstance;
