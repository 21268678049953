import { getSnapshot, model, Model, modelAction, prop } from 'mobx-keystone';
import i18n from 'i18next';
import { isEqual, omit } from 'lodash';
import { reaction } from 'mobx';

import { defaultSearch, searchMinLength } from '@/constants';
import { Exercise, ExerciseResponse, ExercisesResponse } from '@/types';
import { SearchModel, StoreStatusModel } from '@/models';
import { ErrorService, NotificationService, UtilitiesService } from '@/services';

import { HttpService } from '../services';

const search = { ...defaultSearch, order: '-createdAt' };

@model('RootStore/ExerciseStore')
export default class UserStore extends Model({
  exercises: prop<Exercise[]>(() => []),
  exercisesSearch: prop<SearchModel>(() => new SearchModel(search)),
  exerciseSelected: prop<Exercise | undefined>(() => undefined).withSetter(),
  storeStatus: prop<StoreStatusModel>(() => new StoreStatusModel({})),
}) {
  @modelAction
  addExercises = (exercises: Exercise[], total?: number) => {
    this.exercises = exercises;
    if (typeof total !== 'undefined') {
      this.exercisesSearch.total = total;
      // If less items than current page index, reset current page
      if (this.exercisesSearch.total < this.exercisesSearch.page * this.exercisesSearch.limit) {
        this.exercisesSearch.page = 0;
      }
    }
  };

  async getExercises() {
    this.storeStatus.setLoading('getExercises');
    try {
      const response = await HttpService.get<ExercisesResponse>(
        '/manage/exercises',
        UtilitiesService.getSearchQueryString(this.exercisesSearch)
      );
      const { data, total, success } = response.data;
      if (data && success) {
        this.addExercises(data, total);
        this.storeStatus.setLoaded('getExercises');
        return;
      }
    } catch (e) {
      ErrorService.handleError(e);
    }
    NotificationService.error(i18n.t('errors.failedToLoad'));
    this.storeStatus.setLoaded('getExercises');
  }

  async retrieveAndSelectExercise(id: string): Promise<boolean> {
    this.storeStatus.setLoading('retrieveAndSelectExercise');
    this.setExerciseSelected(undefined);
    try {
      const response = await HttpService.get<ExerciseResponse>(`/manage/exercises/${id}`);
      const { data, success } = response.data;
      if (data && success) {
        this.setExerciseSelected(data);
        this.storeStatus.setLoaded('retrieveAndSelectExercise');
        return true;
      }
    } catch (e) {
      ErrorService.handleError(e);
    }
    NotificationService.error(i18n.t('errors.failedToLoad'));
    this.storeStatus.setLoaded('retrieveAndSelectExercise');
    return false;
  }

  async createExercise(item: Partial<Exercise>) {
    this.storeStatus.setLoading('createExercise');
    try {
      const response = await HttpService.post<ExerciseResponse>('/manage/exercises', item);
      const { data, success } = response.data;
      if (data && success) {
        NotificationService.success(i18n.t('success.created'));
        this.storeStatus.setLoaded('createExercise');
        return;
      }
    } catch (e) {
      ErrorService.handleError(e);
    }
    NotificationService.error(i18n.t('errors.failedToSave'));
    this.storeStatus.setLoaded('createExercise');
  }

  async updateSelectedExercise(changes: Partial<Exercise>) {
    this.storeStatus.setLoading('updateSelectedExercise');
    if (!this.exerciseSelected) throw Error('No exercise selected');
    try {
      const { id } = this.exerciseSelected;
      const response = await HttpService.patch<ExerciseResponse>(`/manage/exercises/${id}`, changes);
      const { data, success } = response.data;
      if (data && success) {
        this.setExerciseSelected(data);
        NotificationService.success(i18n.t('success.updated'));
        this.storeStatus.setLoaded('updateSelectedExercise');
        return;
      }
    } catch (e) {
      ErrorService.handleError(e);
    }
    NotificationService.error(i18n.t('errors.failedToSave'));
    this.storeStatus.setLoaded('updateSelectedExercise');
  }

  onAttachedToRootStore(): () => void {
    const exerciseSearchReaction = reaction(
      () => getSnapshot(this.exercisesSearch),
      () => {
        if (!this.exercisesSearch.term || this.exercisesSearch.term.length === 0 || this.exercisesSearch.term.length >= searchMinLength) {
          this.getExercises().catch(console.error);
        }
      },
      {
        // Only return unequal if anything but total changed on searchModel
        equals: (a, b) => isEqual(omit(a, 'total'), omit(b, 'total')),
      },
    );

    return () => {
      exerciseSearchReaction();
    };
  }
}
