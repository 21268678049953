import { HomeOutlined, Dashboard, Notes, Person, School, Quiz } from '@mui/icons-material';

type MainNavItem = {
  label: string;
  route: string;
  icon: JSX.Element;
  needsAuth: boolean;
};

// eslint-disable-next-line import/prefer-default-export
export const mainNavList: MainNavItem[] = [
  {
    label: 'Start',
    route: '/',
    icon: <HomeOutlined fontSize="small" />,
    needsAuth: false,
  },
  {
    label: 'Dashboard',
    route: '/dashboard',
    icon: <Dashboard fontSize="small" />,
    needsAuth: true,
  },
  {
    label: 'Nutzer:innen',
    route: '/dashboard/users',
    icon: <Person fontSize="small" />,
    needsAuth: true,
  },
  {
    label: 'Kurse',
    route: '/dashboard/courses',
    icon: <School fontSize="small" />,
    needsAuth: true,
  },
  {
    label: 'Lektionen',
    route: '/dashboard/units',
    icon: <Notes fontSize="small" />,
    needsAuth: true,
  },
  {
    label: 'Übungen',
    route: '/dashboard/exercises',
    icon: <School fontSize="small" />,
    needsAuth: true,
  },
  {
    label: 'Fragen',
    route: '/dashboard/questions',
    icon: <Quiz fontSize="small" />,
    needsAuth: true,
  },
];
