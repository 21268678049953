import { SnapshotOutOf } from 'mobx-keystone';
import React, { createContext, useMemo } from 'react';

import RootStore from './RootStore';
import initStore from './initStore';

type Props = {
  children: React.ReactNode;
  snapshot?: SnapshotOutOf<RootStore>;
};

export const StoreContext = createContext<RootStore | undefined>(undefined);

export function StoreProvider({ children, snapshot }: Props) {
  const store = useMemo(() => initStore(snapshot), [snapshot]);
  return <StoreContext.Provider value={store}>{children}</StoreContext.Provider>;
}

StoreProvider.defaultProps = {
  snapshot: undefined,
};
