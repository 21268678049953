import { PublicationStatus } from '@/types/PublicationStatus';

import { Language } from './Generic';

export enum CourseStatuses {
  drafted = 'drafted',
  published = 'published',
  unpublished = 'unpublished',
  deleted = 'deleted',
}

export type CourseStatus = `${CourseStatuses}`;

export enum CourseChapterTypes {
  Unit = 'Unit',
  Exercise = 'Exercise',
  ChapterSection = 'ChapterSection',
}

export type Curriculum = CourseChapter[];

export type CourseChapterType = `${CourseChapterTypes}`;

export type ChapterLabel = {
  id: string;
  color: string;
  title: string;
};

export interface CourseChapter {
  completed: boolean;
  hasAccess: boolean;
  id: string;
  indent: number;
  index: number;
  labels?: string[];
  prefix?: string;
  style: string;
  type: CourseChapterType;
  item: {
    _id: string;
    slug: string;
    title: string;
    status: PublicationStatus;
  };
}

export interface CourseChapterCreation {
  indent: number;
  index: number;
  item: string;
  labels?: string[];
  prefix?: string;
  style: string;
  type: CourseChapterType;
}

export enum CourseTypes {
  Test = 'Test',
  Course = 'Course',
}

export type CourseType = `${CourseTypes}`;

export enum CourseThemeList {
  'psy-at-fw' = 'psy-at-fw',
  'psy-at-fd' = 'psy-at-fd',
  'psy-at-fp' = 'psy-at-fp',
  'psy-at-et' = 'psy-at-et',
  'psy-at-ts' = 'psy-at-ts',
  'mympu' = 'mympu',
  'gradient-green' = 'gradient-green',
  'gradient-blue' = 'gradient-blue',
  'gradient-orange' = 'gradient-orange',
  'gradient-turquoise' = 'gradient-turquoise',
  'gradient-violet' = 'gradient-violet',
}

export type CourseTheme = `${CourseThemeList}`;

enum CourseUserStatuses {
  active = 'active',
  submitted = 'submitted',
}

export type CourseUserStatus = `${CourseUserStatuses}`;

export type TestStatisticsSection = {
  exercises: string[];
  label: string;
  abbreviation: string;
  icon: string;
};
export interface Course {
  createdAt: string;
  createdBy: string;
  curriculum: Curriculum;
  description?: string;
  id: string;
  language: Language;
  processingTime?: number; // Has to be added if Test!
  slug: string;
  status: PublicationStatus;
  tags?: string[];
  theme: string;
  title: string;
  type: CourseType;
  updatedAt: string;
  updatedBy?: string;
  testStatisticsSections?: TestStatisticsSection[];
  wpProductId: number;
}

enum CourseMembershipExpirationTypes {
  specificDate = 'specificDate',
  globalDate = 'globalDate',
}

export type CourseMembershipExpirationType = `${CourseMembershipExpirationTypes}`;

export type CourseMembership = {
  id: string;
  title: string;
  course: string;
  validUntil?: string;
  updatedAt: string;
  updatedBy: string;
  version: number;
  status: PublicationStatus;
  expirationType?: CourseMembershipExpirationType;
  extendBy?: string;
};
