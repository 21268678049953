import 'reflect-metadata';
import { CacheProvider } from '@emotion/react';
import { CssBaseline, ThemeProvider, StyledEngineProvider } from '@mui/material';
import i18n from 'i18next';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { SnackbarProvider } from 'notistack';
import React from 'react';
import { initReactI18next } from 'react-i18next';
import createCache from '@emotion/cache';
import Head from 'next/head';
// import AdapterLuxon from '@mui/x-date-pickers/AdapterLuxon';
// import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { SessionProvider } from 'next-auth/react';
import type { AppProps } from 'next/app';
import { Settings } from 'luxon';

import { AuthService } from '@/services';
import { StoreProvider } from '@/stores';
import { de } from '@/languages';
import theme from '@/theme';
import AccountWrapper from '@/components/account/AccountWrapper';
import { Snackbar } from '@/services/NotificationService';
import { isDevelopment } from '@/constants';

// Setup i18n
i18n
  .use(initReactI18next)
  .init({
    resources: {
      de,
    },
    fallbackLng: 'de',
    interpolation: {
      escapeValue: false,
    },
  })
  .catch(console.error);

// Setup luxon
Settings.defaultLocale = i18n.language;

// Use emotion cache
const cache = createCache({ key: 'css', prepend: true });
cache.compat = true;

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false, // don't refetch queries (e.g. getDeviceId()) on every page change
      refetchOnMount: false, // don't refetch queries (e.g. getDeviceId()) every time when going back to the start page
    },
  },
});

function App({ Component, pageProps: { session, ...pageProps } }: AppProps): JSX.Element {
  return (
    <CacheProvider value={cache}>
      <Head>
        <meta name="viewport" content="initial-scale=1, width=device-width" />
        <meta name="theme-color" content={theme.palette.primary.main} />
        <link rel="apple-touch-icon" sizes="180x180" href="/assets/favicons/apple-touch-icon.png" />
        <link rel="icon" type="image/png" sizes="32x32" href="/assets/favicons/favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="/assets/favicons/favicon-16x16.png" />
        <link rel="manifest" href="/assets/favicons/site.webmanifest" />
        <link rel="mask-icon" href="/assets/favicons/safari-pinned-tab.svg" color="#000000" />
        <link rel="icon" href="/assets/favicons/favicon.ico" />
        <meta name="msapplication-TileColor" content="#000000" />
        <meta name="msapplication-config" content="/assets/favicons/browserconfig.xml" />
        <meta name="theme-color" content="#ffffff" />
        {/* TODO: add security headers */}
      </Head>
      <StyledEngineProvider injectFirst>
        {/* TODO: Fix LocalizationProvider */}
        {/* <LocalizationProvider dateAdapter={AdapterLuxon}> */}
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <QueryClientProvider client={queryClient}>
              {isDevelopment && <ReactQueryDevtools initialIsOpen={false} />}
              <StoreProvider>
                <SnackbarProvider maxSnack={3}>
                  <Snackbar />
                  <SessionProvider session={session} refetchInterval={AuthService.getAccessTokenRefreshIntervalInSec()}>
                    <AccountWrapper>
                      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                      <Component {...pageProps} />
                    </AccountWrapper>
                  </SessionProvider>
                </SnackbarProvider>
              </StoreProvider>
            </QueryClientProvider>
          </ThemeProvider>
        {/* </LocalizationProvider> */}
      </StyledEngineProvider>
    </CacheProvider>
  );
}

export default App;
